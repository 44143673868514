import { FunctionComponent } from 'react';
import Footer from '../footer/Footer';
import CookieConsent from '../gdpr/CookieConsent';
import Header from '../header/Header';

interface LayoutProps
{
    children: JSX.Element | JSX.Element[];
}

const Layout: FunctionComponent<LayoutProps> = (props) =>
{
    /** Render */
    return (
        <>
                <Header />
                
                <div className="container">
                    <div className="row no-gutters justify-content-center">
                        <div className="view-container col-lg-9 py-3">
                            <div>{props.children}</div>
                        </div>
                    </div>
                </div>
                
                <Footer />

                <CookieConsent />
        </>
    );
}

export default Layout;