import Link, { LinkProps } from 'next/link';
import { CSSProperties, FunctionComponent, HTMLAttributeAnchorTarget } from 'react';

interface ALinkProps extends LinkProps
{
    href: string;
    className?: string;
    style?: CSSProperties;
    target?: HTMLAttributeAnchorTarget;
    children?: any;
    dangerouslySetInnerHTML?: any;
    /** If true, prevent the standard link behavior. */
    preventDefault?: boolean;
    onClick?: () => void;
}

/** React Component: Wrapper around the Next.js Link component. */
const ALink: FunctionComponent<ALinkProps> = (props) =>
{
    const defaultFunction = (e: any) => 
    {
        if (props.href == '#' || props.preventDefault)
        {
            e.preventDefault();
        }

        if (props.onClick) props.onClick();
    }

    /** Render */
    return (
        <>
            {props.dangerouslySetInnerHTML === undefined &&
                <Link href={props.href} >
                    <a
                        className={props.className}
                        style={props.style}
                        target={props.target}
                        onClick={e => defaultFunction(e)}
                    >
                        {props.children}
                    </a>
                </Link>
            }

            {props.dangerouslySetInnerHTML !== undefined &&
                <Link href={props.href} >
                    <a
                        className={props.className}
                        style={props.style}
                        target={props.target}
                        onClick={e => defaultFunction(e)}
                        dangerouslySetInnerHTML={props.dangerouslySetInnerHTML}
                    />
                </Link>
            }
        </>
    );
}

export default ALink;