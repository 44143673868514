import { FunctionComponent, useEffect, useRef } from 'react';
import { useTranslate } from '@xFrame4/components/Hooks';
import config, { AppLanguage } from '@config';
import Link from 'next/link';
import { useAppStateSelector } from '@redux/hooks';
import { tl } from '@xFrame4/common/Utils';
import { AppTracker } from '@business/AppTracker';
import ALink from '@xFrame4/components/next/ALink';
import SmoothImage from '@xFrame4/components/common/SmoothImage';

interface HeaderProps
{

}

const Header: FunctionComponent<HeaderProps> = (props) =>
{
    const t = useTranslate();
    const language: AppLanguage = useAppStateSelector((state) => state.layout.language);
    const refNavbarCollapse = useRef<HTMLDivElement>(null);

    /** Track pageview */
    useEffect(() => {
        let dummy = AppTracker.instance;
        const onWindowLoad = () => 
        {   
            AppTracker.instance.logPageView();
        };
        
        if (document.readyState === 'complete') 
        {
            onWindowLoad();
        }
        else
        {
            window.addEventListener('load', onWindowLoad);

            return () => { window.removeEventListener('load', onWindowLoad); };
        }
    }, []);

    /**
     * A menu item has been clicked.
     */
    const onMenuItemClick = () =>
    {
        // Collapse the menu
        refNavbarCollapse.current?.classList.remove('show');
    }

    /** Language menu items */
    let languageMenuItems = config.languages.map(language => {
        return (
            <li key={language.code}>
                <ALink href={config.appUrl + language.code + '/pixies'} className="dropdown-item cursor-pointer">
                    {tl('APP_LANGUAGE', language.code)}
                </ALink>
            </li>
        )
    });

    /** Render */
    return (
        <header>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <ALink href={config.appUrl + language.code + '/pixies'} className="navbar-brand cursor-pointer" style={{ width: '2.3rem' }}>
                        <SmoothImage 
                            src={config.defaultPublicDirBaseUrl + 'img/logo/logo.png'} 
                            className="img-fluid logo" 
                            width={32}
                            height={32}
                            alt={t('APP_TITLE')}
                        />
                    </ALink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div ref={refNavbarCollapse} className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown">
                                <div className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    {t('LANGUAGE')}
                                </div>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown" onClick={() => onMenuItemClick()}>
                                    {languageMenuItems}
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default Header;