import config, { AppLanguage } from '@config';
import { useAppStateSelector } from '@redux/hooks';
import { useTranslate } from '@xFrame4/components/Hooks';
import ALink from '@xFrame4/components/next/ALink';
import Link from 'next/link';
import { FunctionComponent } from 'react';

interface FooterProps
{
}

const Footer: FunctionComponent<FooterProps> = (props) =>
{
    const t = useTranslate();
    const language: AppLanguage = useAppStateSelector((state) => state.layout.language);

    /** The footer menu links. */
    let cmpLinks = config.staticContentSlugs.map(slug =>
    {
        return (
            <li key={slug} className="mb-1">
                <ALink 
                    href={config.appUrl + language.code + '/static/' + slug}
                    className="static cursor-pointer text-muted text-decoration-none"
                >
                    {t(slug.toUpperCase())}
                </ALink>
            </li>
        )
    },
    );

    /** Render */
    return (
        <footer className="container pt-4 my-md-5 pt-md-5 pb-3 border-top">
            <div className="row pb-env">
                <div className="col-6">
                    <div className="h5 font-weight-bold mb-2">{t('APP_TITLE')}</div>
                    <small className="d-block mb-3 text-muted">© 2022</small>
                </div>
                <div className="col-6">
                    <div className="h5">{t('SITE_INFO')}</div>
                    <ul className="list-unstyled text-small">
                        {cmpLinks}
                    </ul>
                </div>
            </div>
        </footer>
    );
}



export default Footer;